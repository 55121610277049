const NoPage = () => {
    return (
        <div className="container spacing-section text-center">
            <h1 className="mb-0 fw-bold">404</h1>
            <h2 className="mt-0">Page Not Found</h2>
            <a className="btn btn-primary" href="/">Zurück zur Startseite</a>
        </div>
    )
};

export default NoPage;
