import {Outlet} from "react-router-dom";
import Footer from "../components/navigation/footer";
import Nav from "../components/navigation/nav";

const Layout = () => {
    return (
        <>
            <Nav />

            <Outlet/>

            <Footer />
        </>
    )
};

export default Layout;
