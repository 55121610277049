import '../styles/Impress.scss';

const Impress = () => {
    return (
        <>
            <div className="container spacing-section" id="impress">
                <h1>Impressum</h1>

                <ul>
                    <li><small>Ansprechperson:</small> Anuschka Scholten</li>
                    <li><small>Postleitzahl:</small> 74080</li>
                    <li><small>Stadt:</small> Heilbronn</li>
                    <li><small>Straße:</small> Im Kreuzgrund 40</li>
                    <li><small>Telefon:</small> 07131/962453</li>
                    <li><small>Handy:</small> 01726301892</li>
                    <li><small>Fax:</small> 07131628497</li>
                    <li><small>Email:</small> th.scholten@t-online.de</li>
                    <li><small>Ust-ld-Nr.:</small> DE 92 678 307 159</li>
                </ul>
            </div>
        </>
    );
};

export default Impress;
