import '../styles/History.scss'; // Importiere die zugehörigen CSS-Stile


const History = () => {
    const events = [
        {
            title: 'Gründung',
            description: 'Im Jahre 1969 wurde unser Unternehmen gegründet. Was als kleines Vorhaben begann, entwickelte sich schnell zu einem florierenden Geschäft, das auf festen Werten und einer klaren Vision basiert.'
        },
        {
            title: 'Aufbau',
            description: 'In den folgenden Jahren erfolgte der Aufbau eines soliden Familienbetriebs. Durch harte Arbeit und Hingabe wuchs das Unternehmen stetig und gewann das Vertrauen unserer Kunden.'
        },
        {
            title: 'Anstellung',
            description: 'Mit der Expansion des Unternehmens kamen auch neue Herausforderungen. Bald arbeiteten fünf engagierte Mitarbeiter unter einem Dach, um gemeinsam die Vision des Unternehmens weiter voranzutreiben.'
        },
        {
            title: 'Wachstum',
            description: 'Dank der kontinuierlichen Weiterentwicklung und Anpassung an die Bedürfnisse des Marktes erlebte unser Unternehmen ein nachhaltiges Wachstum. Heute stehen wir als Symbol für Qualität und Verlässlichkeit.'
        },
        {
            title: 'Digitalisierung',
            description: 'Im Zuge der digitalen Transformation implementierten wir neue Technologien, die unsere Prozesse effizienter machten und uns halfen, unseren Kundenservice zu verbessern.'
        }
    ];

    return (
        <div className="container  spacing-bottom">
            <h2 className="text-center">Historie</h2>

            <div className="position-relative">

                <div className="timeline-line"></div>
                {events.map((event, index) => (
                    <div
                        key={index}
                        className={`timeline-event ${index % 2 === 0 ? 'left' : 'right'}`}
                    >
                        <div className="timeline-content">
                            <h3>{event.title}</h3>
                            <small>{event.description}</small>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default History;