import PropTypes from 'prop-types';
import '../styles/hero.scss'

function Hero(props) {
    return (
        <div className="hero">
            <img src={props.src} alt={props.alt} />
            <h1>
                <b>Scholten </b>
                <br/>
                Container&shy;service
                <br/>
                <a className="btn btn-primary" href="tel:+491726301892">Jetzt anrufen +49 1726 30 18 92</a>
            </h1>
        </div>
    );
}

Hero.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
};

Hero.alt =  'Default image description';


export default Hero;