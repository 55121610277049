import '../styles/ContactPage.scss';

const Contact = () => {
    return (
        <>
            <div className="container spacing-section">
                <h1>Kontaktieren Sie uns</h1>
                <p>Wir freuen uns auf Ihre Nachricht. Egal ob Sie Fragen haben oder Unterstützung benötigen, unser
                    Team steht Ihnen zur Verfügung. Lassen Sie uns gemeinsam die beste Lösung für Sie finden.</p>
                <div className="contact-details">
                    <p className="phone">Telefon: +491726301892</p>
                </div>
                <div className="button-container">
                    <button className="btn btn-primary"
                            onClick={() => window.location.href = 'tel:+491726301892'}>Jetzt anrufen
                    </button>
                </div>
            </div>
        </>
    );
};

export default Contact;
