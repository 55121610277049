import {Link} from "react-router-dom";

function Nav() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img className="logo" src="/assets/logo.svg" alt="Logo"/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/kontakt">Kontakt</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/impressum">Impressum</Link>
                        </li>
                        <li>
                            <button className="btn"
                                      onClick={() => window.location.href = 'tel:+491726301892'}>
                            Jetzt anrufen +49 1726 30 18 92
                        </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Nav;
